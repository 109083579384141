
.profile_info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_info img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile_info > div {
    display: flex;
    flex-direction: column;
    line-height: 15px;
}

.profile_info > div div {
    color: white;
    font-family: 'Saira Condensed', sans-serif;
}

.profile_info > div div:first-child {
    font-weight: 600;
    text-transform: uppercase;
}