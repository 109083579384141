.day_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    height: fit-content;
    background: rgba(236, 239, 241, 0.33);
    border: 1px solid #E0E0E0;
    position: relative;
}

.day_container > span {
    display: flex;
    flex-direction: column;
    color: #656464;
    background-color: #F5F5F5;
    border: 1px solid #E0E0E0;
    text-align: center;
    padding: 5px;
}

.hour_delimiter {
    display: flex;
    height: 30px;
    border-top: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.hour_delimiter:hover {
    background-color: #E0E0E0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.hour_delimiter:hover::before {
    display: flex;
    font-size: 8px;
    text-align: center;
    content: "Click to schedule class";
    box-sizing: border-box;
    width: 47px;
}

.class_container_day {
    position: absolute;
    width: 100%;
}

.hour_delimiter_edit {
    display: flex;
    height: 30px;
    border-top: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.select_class_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-row-gap: 8px;
    grid-column-gap: 8px;
}

.select_class {
    width: 100%;
    height: 50px;
    background-color: transparent;
}

.selected_class {
    box-shadow: 0px 0px 13px 4px #505251;
    border-radius: 10px;
    position: relative;
}

.selected_class::after{
    content: "Selected";
    position: absolute;
    font-size: 10px;
    color: #fff;
    background-color: #505251;
    padding: 2px;
    border-radius: 5px;
    top: 0;
    right: 0;
}

.no_class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    color: #ccc;
}

