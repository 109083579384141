.class_container {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid;
    padding: 5px;
    box-sizing: border-box;
    height: calc(100% - 1px);
}

.class_icon {
    display: flex;
}

.class_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 14px;
}

.class_name {
    font-family: 'Saira Condensed', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.class_time {

}

.wod{
    background-color: #b62b70;
    border-color: #b62b70;
    color: white;
    fill: white;
}

.gymnastics{
    background-color: #380985;
    border-color: #380985;
    color: white;
    fill: white;
}
.open_gym{
       background-color: #126f53;
       border-color: #126f53;
       color: white;
       fill: white;
}

.weight_lifting{
    background-color: #b1580e;
    border-color: #b1580e;
    color: white;
    fill: white;
}

.team_wod{
    background-color: #0e68b1;
    border-color: #0b5591;
    color: white;
    fill: white;
}

.spinning{
    background-color: #459e74;
    border-color: #46ad7c;
    color: white;
    fill: white;
}

.unknown_class{
    background-color: #b1b1b1;
    border-color: #b1b1b1;
    color: white;
    fill: white;
}

.hover_container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #0c0c0cc9;
    border-radius: 8px;
    text-transform: uppercase;
    color: white;
}

.class_container:hover .hover_container{
    display: flex;
}

.loading_container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left:0;
    border-radius: 8px;
    background-color: #0c0c0cc9;
}
.loading_container svg{
    width: 30px;
}
.loading_container > div{
   display: flex;
}
