.week_container{
    display: flex;
    flex-direction: row;
    overflow: scroll;
    background-color: white;
}
.hours_container{
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    height: fit-content;
    background-color: #F5F5F5;
}

.hour{
    display: flex;
    height: 60px;
    border-top: 1px solid #E0E0E0;
    position: relative;
    box-sizing: border-box;
    color: #656464;
    background-color: #F5F5F5;
}

.hour span:first-child{
    font-size: 25px;
    padding: 0;
    margin: 0;
}

.hour span:last-child{
    font-size: 10px;
    padding: 0;
    margin: 0;
}

.loading_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 100;
}
