.menu_container {
    display: flex;
    height: 50px;
    width: 100%;
    background-color: #495057;
    align-items: center;
    justify-content: space-between;
}

.layout_container {
    display: flex;
    flex-direction: column;
}

.outlet_container {
    height: calc(100vh - 50px);
    overflow: auto;
}

.menu_container > div {
    padding: 10px;
    display: flex;
}

.menu_container span {
    display: flex;
    color: white;
    padding: 10px;
    border-bottom: 2px solid transparent;
}

.menu_container span a {
    text-decoration: none;
    color: white;
}

.menu_container span:hover {
    border-bottom: 2px solid white;
    cursor: pointer;
}
